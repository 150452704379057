<template>
	<form @submit.prevent="checkForm" class="mb-4">
		<div class="row">
			<div class="col-6">
				<a href="#" @click.prevent="$emit('update:current_step', current_step-1)"><font-awesome-icon v-if="current_step > 1" :icon="['fal', 'long-arrow-left']" /></a>
			</div>
			<div class="col-6 text-right">
				({{ $t('global.step') }} : {{ current_step }} / {{ nb_step }})
			</div>
		</div>

		<div class="row">
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>

			<div class="col-12" v-if="current_step == 1">
				<div class="form-group">
					<label for="contractconfigtypemonte_label">{{ $t('monte.label_type_monte') }} *</label>
					<input type="text" v-model="form.contractconfigtypemonte_label" id="contractconfigtypemonte_label" class="form-control" :class="{ 'is-invalid': errors && errors.indexOf('label') > -1 }">
				</div>

				<div class="form-group">
					<label for="contractconfigtypemonte_id">{{ $t('monte.type_monte') }} *</label>
					<e-select
						v-model="form.contractconfigtypemonte_typemonte"
						track-by="name"
						label="name"
						:placeholder="labelTitleTypeMonte"
						:selectedLabel="selectedLabel"
						:options="type_monte_options"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
						:class="{ 'is-invalid': errors && errors.indexOf('type_monte') > -1 }"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group">
					<label for="contractconfigtypemonte_paillette">{{ $t('monte.nombre_paillettes') }} *</label>
					<input type="text" v-model="form.contractconfigtypemonte_paillette" id="contractconfigtypemonte_paillette" class="form-control">
				</div>

				<div class="form-group">
					<b-form-checkbox v-model="form.contractconfigtypemonte_archive">
						{{ $t('monte.archive') }}
					</b-form-checkbox>
				</div>

				<div class="form-group">
					<label for="contractconfigtypemonte_comment">{{ $t('monte.commentaire_type_monte') }}</label>
					<textarea v-model="form.contractconfigtypemonte_comment" id="contractconfigtypemonte_comment" rows="5" class="form-control"></textarea>
				</div>

				<div class="form-group">
					<b-form-checkbox v-model="restriction_pays">{{ $t('monte.restriction_pays') }}</b-form-checkbox>
				</div>

				<div v-if="restriction_pays" class="form-group">
					<label>{{ $t('monte.pays_dispo_conditions') }}</label>
					<e-select
						track-by="id"
						label="name"
						:options="countries_iso_array"
						:searchable="true"
						:allow-empty="true"
						:show-labels="false"
						:multiple="true"
						:search="true"
						:placeholder="labelTitle"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						v-model="cc_countries"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="option" slot-scope="props">
							{{ props.option.name }}
						</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>
			<div class="col-12" v-else-if="current_step == 2">
				<div class="form-group" v-for="(condition, key) in conditions" v-bind:key="key">
					<label :for="'conditon_' + key">{{ $t(condition.contractconditions_label) }}</label>
					<e-select
						v-model="form.articles[condition.contractconditions_id]"
						track-by="id"
						label="name"
						:placeholder="labelTitleArticle"
						:selectedLabel="selectedLabel"
						:options="articles"
						:searchable="true"
						:allow-empty="true"
						:show-labels="false"
						:multiple="true"
						group-label="tiers_rs"
						group-values="articles"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>

					<template v-if="local_articles[condition.contractconditions_id] != undefined && local_articles[condition.contractconditions_id].length > 0">
						<div class="row mt-2">
							<div class="col-6">
								<label>{{ $t('invoice.invoicedetails_htunit') }}</label>
							</div>
							<div class="col-3">
								<label>{{ $t('acte.quantite') }}</label>
							</div>
							<div class="col-3">
								<label>{{ $t('acte.ht') }}</label>
							</div>
						</div>

						<div class="row" v-for="(article, index) in local_articles[condition.contractconditions_id]" :key="index">

							<div class="col-6">
								<b-input-group>
									<b-input-group-prepend>
										<span class="input-group-text">
											{{ article.name }} / {{ article.entity }}
										</span>
									</b-input-group-prepend>
									<DecimalInput v-model="article.htunit" @change="setHTCondition(condition.contractconditions_id, index, article.htunit)"/>
								</b-input-group>
							</div>

							<div class="col-3">
								<b-input type="text" class="form-control" v-model="article.qte" @change="setQteCondition(condition.contractconditions_id, index, article.qte)"/>
							</div>

							<div class="col-3">
								<b-input v-if="show_ht" type="text" class="form-control" v-model="article.ht" :disabled="true"/>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</form>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import Invoice from "@/mixins/Invoice.js"
	import Accounting from "@/mixins/Accounting.js"
	import { EventBus } from 'EventBus'
	import ContractMixin from "@/mixins/Contract.js"
	import _differenceBy from 'lodash/differenceBy'
	import Countries from '@/assets/lang/countries/countries'


	export default {
		name: "ConditionForm",
		mixins: [Config, TableAction, Navigation, Invoice, Accounting, ContractMixin],
		props: ['current_step', 'nb_step', 'type_monte', 'conditions', 'contractconfig_id', 'contract_type_monte'],
		data () {
			return {
				form_message: '',
				processing: false,
				errors: [],
				form: {
					contractconfigtypemonte_label: null,
					contractconfigtypemonte_typemonte: null,
					contractconfigtypemonte_comment: null,
					contractconfigtypemonte_paillette: 0,
					contractconfigtypemonte_archive: false,
					articles: []
				},
				articles: [],
				labelTitleTypeMonte: this.getTrad("monte.rechercher"),
				labelTitleArticle: this.getTrad("monte.rechercher_article"),
				selectedLabel: this.getTrad("global.selected_label"),
				local_articles: [],
				show_ht: true,
				vat: [],
				restriction_pays: false,
				cc_countries: [],
				countries_dispo: [],
				countries_iso_array_temp: [],
				countries_iso_array: [],
				labelTitle: this.getTrad("global.press_enter_to_remove"),
				deselectLabel: this.getTrad("global.selected_label"),
				enter_to_select: this.getTrad("compta.search_country"),
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {

				if(Object.keys(this.contract_type_monte).length > 0) {

					//Je suis en modif
					this.form.contractconfigtypemonte_label = this.contract_type_monte.contractconfigtypemonte_label
					this.form.contractconfigtypemonte_comment = this.contract_type_monte.contractconfigtypemonte_comment
					this.form.contractconfigtypemonte_paillette = this.contract_type_monte.contractconfigtypemonte_paillette
					this.form.contractconfigtypemonte_archive = this.contract_type_monte.contractconfigtypemonte_archive

					this.form.contractconfigtypemonte_typemonte = {
						id: this.contract_type_monte.contract_type_monte.contracttypemonte_id,
						name: this.getTrad(this.contract_type_monte.contract_type_monte.contracttypemonte_label),
					}

					//Je vais remplir les articles
					for (let i = 0; i < this.conditions.length; i++) {
						const current_cond = this.conditions[i]

						if(this.contract_type_monte[current_cond.contractconditions_type] != undefined) {
							let temp_tab = []
							for (let j = 0; j < this.contract_type_monte[current_cond.contractconditions_type].length; j++) {
								temp_tab.push({
								 	id: this.contract_type_monte[current_cond.contractconditions_type][j].articles.articles_id,
								 	name: this.contract_type_monte[current_cond.contractconditions_type][j].articles.articles_label,
								 	ht: this.contract_type_monte[current_cond.contractconditions_type][j].contractconfigconditionsarticles_ht / 100,
								 	htunit: this.contract_type_monte[current_cond.contractconditions_type][j].contractconfigconditionsarticles_htunit / 100,
								 	qte: this.contract_type_monte[current_cond.contractconditions_type][j].contractconfigconditionsarticles_qte,
								 	ttc: this.contract_type_monte[current_cond.contractconditions_type][j].contractconfigconditionsarticles_ttc / 100,
								 	vat: this.contract_type_monte[current_cond.contractconditions_type][j].articles.vat.vat_id,
									entity: this.contract_type_monte[current_cond.contractconditions_type][j].articles.accounting_account.accounting_plan.accountingplan_label
								})
							}
							this.$set(this.form.articles, current_cond.contractconditions_id, temp_tab)
						}
					}

					if(this.contract_type_monte.pays_tab.length > 0) {
						this.restriction_pays = true
					}
				}

				let entities = await this.loadAccountingPlans()
				this.articles = []
				let articlesPromises = []

				for (let index in entities) {
					let promise = this.loadArticlesByEntity(entities[index].accountingplan_tiers)
					.then(articles => {
						for (let index_a in articles) {
							let filter = this.articles.findIndex(function(e) { return e.tiers_rs === entities[index].tiers.tiers_rs; })

							if(filter == -1) {
								this.articles.push({
									tiers_rs: entities[index].tiers.tiers_rs,
									articles: [
										{
											id: articles[index_a].articles_id,
											name: articles[index_a].articles_label,
											ht: articles[index_a].articles_ht,
											htunit: articles[index_a].articles_ht,
											qte: 1,
											ttc: articles[index_a].articles_ttc,
											entity: entities[index].accountingplan_label,
											vat: articles[index_a].articles_vat
										}
									]
								})
							}
							else {
								this.articles[filter].articles.push({
									id: articles[index_a].articles_id,
									name: articles[index_a].articles_label,
									ht: articles[index_a].articles_ht,
									htunit: articles[index_a].articles_ht,
									qte: 1,
									ttc: articles[index_a].articles_ttc,
									entity: entities[index].accountingplan_label,
									vat: articles[index_a].articles_vat
								})
							}	
						}
					})

					articlesPromises.push(promise)
				}

				await Promise.all(articlesPromises)

				this.vat = []

				const vat = await this.getVat()
				for (let i = 0; i < vat.length; i++) {
					this.vat.push({
						id: vat[i].vat_id,
						name: vat[i].vat_label,
						value: vat[i].vat_value,
					})
				}

				this.$emit('update:ready', true)
			},

			async checkForm(){
				this.errors = []

				if(!this.processing) {
					this.processing = true
				
					this.$emit('update:processing', true)

					if(!this.form.contractconfigtypemonte_label) {
						this.errors.push("label")
						this.$emit('update:current_step', 1)
					}

					if(!this.form.contractconfigtypemonte_typemonte) {
						
						this.errors.push("type_monte")
						this.$emit('update:current_step', 1)
					}

					let nb_articles = 0
					for (let index = 0; index < this.form.articles.length; index++) {
						const element = this.form.articles[index];
						
						if(element != undefined && element.length > 0){
							nb_articles += element.length
						}
					}

					if(nb_articles == 0) {
						this.errors.push("articles")
						this.form_message = "monte.erreur_ajout_minimum_article"
					}

					if(this.errors.length > 0) {
						this.processing = false
						this.$emit('update:processing', false)
						return false
					}

					let result = null
					let countries_ids = this.restriction_pays ? this.cc_countries.map(c => c.id) : []

					if(Object.keys(this.contract_type_monte).length > 0) {
						result = await this.editContractConditions(this.contract_type_monte.contractconfigtypemonte_id, this.contractconfig_id, this.form, this.local_articles, countries_ids)
					}
					else {
						result = await this.addContractConditions(this.contractconfig_id, this.form, this.local_articles, countries_ids)
					}


					if(result) {
						this.$emit('update:processing', false)
						this.processing = false
						this.successToast()
						return true
					}
					else {
						this.processing = false
						this.$emit('update:processing', false)
					}
				}
			},

			setHTCondition(condition_id, key, value) {
				this.show_ht = false

				value = value.replace(',', '.')
				this.local_articles[condition_id][key].htunit = value
				this.local_articles[condition_id][key].ht = this.local_articles[condition_id][key].qte * value

				const vat = this.vat.find(vat => { return vat.id == this.local_articles[condition_id][key].vat })
				const ht = parseFloat(this.local_articles[condition_id][key].ht)
				let ttc = (vat.value * ht) + ht
				if(ttc.toString().indexOf(".") > -1) {
					ttc = ttc.toString().substr(0, ttc.toString().indexOf(".") + 3).toFloat()
				}
				this.local_articles[condition_id][key].ttc = ttc

				this.show_ht = true
			},

			setQteCondition(condition_id, key, value) {
				this.show_ht = false

				value = value.replace(',', '.')
				this.local_articles[condition_id][key].qte = value
				this.local_articles[condition_id][key].ht = this.local_articles[condition_id][key].htunit * value

				const vat = this.vat.find(vat => { return vat.id == this.local_articles[condition_id][key].vat })
				const ht = parseFloat(this.local_articles[condition_id][key].ht)
				let ttc = (vat.value * ht) + ht
				if(ttc.toString().indexOf(".") > -1) {
					ttc = ttc.toString().substr(0, ttc.toString().indexOf(".") + 3).toFloat()
				}
				this.local_articles[condition_id][key].ttc = ttc

				this.show_ht = true
			},

			loadCountries() {
				if(this.countries_iso_array.length > 0) return false

				const countries = Countries[Vue.i18n.locale()].label
				const countries_iso = Countries[Vue.i18n.locale()].iso_3

				let countries_values = Object.values(countries)
				let countries_iso_values = Object.values(countries_iso)

				let temp = []
				for (let i = 0; i < countries_values.length; i++) {
					temp.push(countries_values[i]+' - '+countries_iso_values[i])
				}

				let countries_iso_array_temp = []
				let countries_keys = Object.keys(countries)
				let countries_formatted = Object.fromEntries(countries_keys.map((_, i) => [countries_keys[i], temp[i]]))
				for (let [key, value] of Object.entries(countries_formatted)) {
					countries_iso_array_temp.push({
						id: key,
						name: value
					})
				}
				this.countries_iso_array = countries_iso_array_temp.sort((a,b) => {
					// return a.name - b.name;
					var x = this.accentsTidy(a.name.toLowerCase());
				    var y = this.accentsTidy(b.name.toLowerCase());
				    return x < y ? -1 : x > y ? 1 : 0;
				});

				if(this.contract_type_monte.pays_tab.length > 0) {
					this.cc_countries = this.countries_iso_array.filter(country => this.contract_type_monte.pays_tab.includes(parseInt(country.id)))
				}
			}


		},
		computed: {
			type_monte_options(){
				let tab = []
				for(let index in this.type_monte){

					tab.push({
						id: this.type_monte[index].contracttypemonte_id,
						name: this.getTrad(this.type_monte[index].contracttypemonte_label),
					})
				}

				return tab
			}
		},
		watch:{
			'form.articles': {
				deep: true,
				handler(val){
					for (let index in val) {
						if(this.local_articles[index] == undefined) {
							this.local_articles[index] = this.deepClone(val[index])
						}
						else{
							const difference = _differenceBy(val[index], this.local_articles[index], 'id')
							if(difference.length > 0) {
								this.local_articles[index].push(this.deepClone(difference[0]))
							}
							else{
								const difference = _differenceBy(this.local_articles[index], val[index], 'id')
								if(difference.length > 0) {
									let local = this.deepClone(this.local_articles[index])
									local = local.filter(item => item.id !== difference[0].id)
									this.$set(this.local_articles, index, local)
								}
							}
						}
					}
				},
			},
			restriction_pays(val) {
				if(val) {
					this.loadCountries()
				}
			}
		},
		components: {
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			DecimalInput: () => import('GroomyRoot/components/Inputs/DecimalInput')
		}
	}
</script>